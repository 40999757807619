import React, { useState } from "react";
import { Button } from "semantic-ui-react";
// UX
import CardReserveBedQueue from "./CardReserveBedQueue";
import CardReserveTransferQueue from "./CardReserveTransferQueue";
import { useIntl } from "react-intl";

const CardADM = (props: any) => {
  const intl = useIntl();
  const [mode, setMode] = useState("bed_queue");

  const handleChangeMode = (inputMode: string)=> {
    props.setProp(`SetBedSequence`, {
      ...props.SetBedSequence,
      selectedRoom: null,
      roomList: [],
      inputHN: "",
      isHN: false,
    });

    setMode(inputMode);
  }

  return (
    <div
      id="CardADM"
      style={{
        height: "100%",
        width: "100%",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <MenuItem
          menu="bed_queue"
          mode={mode}
          name={intl.formatMessage({ id: "คิวใบจองเตียงผู้ป่วย" })}
          setMode={handleChangeMode}
        />
        <MenuItem
          menu="transfer_queue"
          mode={mode}
          name={intl.formatMessage({ id: "คิวย้าย Ward" })}
          setMode={handleChangeMode}
        />
      </div>

      {mode === "bed_queue" ? (
        <CardReserveBedQueue
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          crdAdmitOrderController={props.crdAdmitOrderController}
          subICD10DetailController={props.subICD10DetailController}
          printListController={props.printListController}
          scannedDocumentController={props.scannedDocumentController}
          subICDController={props.subICDController}
          // callback
          setProp={props.setProp}
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          // data
          masterOptions={props.masterOptions}
          SetBedSequence={props.SetBedSequence}
          filterReserveBedQueue={
            props.SetBedSequence?.filterReserveBedQueue || {}
          }
          django={props.django}
          eocIsLoading={props.eocIsLoading}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          languageUX={props.languageUX}
        />
      ) : mode === "transfer_queue" ? (
        <CardReserveTransferQueue
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          printListController={props.printListController}
          subICDController={props.subICDController}
          // callback
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          AdmitChangeSequence={props.AdmitChangeSequence}
          // data
          masterOptions={props.masterOptions}
          SetBedSequence={props.SetBedSequence}
          filterReserveTransferQueue={
            props.SetBedSequence?.filterReserveTransferQueue || {}
          }
          searchedItemList={props.searchedItemList}
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          languageUX={props.languageUX}
        />
      ) : null}
    </div>
  );
};

export const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setMode(props.menu)}
    disabled={typeof props.active === "undefined" ? false : !props.active}
    color={
      props.active
        ? props.color || "blue"
        : props.menu === props.mode
        ? "blue"
        : undefined
    }
  >
    {props.name}
  </Button>
);

export default CardADM;
