import React, { useEffect } from "react";
import { Button, List } from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardAddCoPhysicianUX from "react-lib/apps/HISV3/ADM/CardAddCoPhysicianUX";

type CardAddCoPhysicianProps = {
  runSequence: any;
  onEvent: any;
  setProp: any;
  errorMessage: any;
  successMessage: any;
  buttonLoadCheck: any;
  languageUX: any;
  AddCoPhysicianSequence: any;
  // function
  onClose?: () => void;
  onRefreshAndClose?: () => void;
  // data
  searchedItemListWithKey: any;
  encounter_id?: number;
  primary_doctor?: string;
  co_physician?: any[];
};

const CARD: string = "CardAddCoPhysician";

const CardAddCoPhysician = (props: CardAddCoPhysicianProps) => {
  useEffect(() => {
    props.runSequence({
      sequence: "AddCoPhysician",
      restart: true,
      coPhysicianList: props.co_physician,
    });
  }, []);

  const handleSelectCoPhysician = (value: any, key: any, item: any) => {
    props.setProp("AddCoPhysicianSequence.coPhysician", item);
  };

  const handleAddCoPhysician = () => {
    let result = {
      ...props.AddCoPhysicianSequence,
      coPhysician: null,
      coPhysicianList: [
        ...(props.AddCoPhysicianSequence.coPhysicianList || []),
        props.AddCoPhysicianSequence.coPhysician,
      ],
    };
    props.setProp("AddCoPhysicianSequence", result);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "AddCoPhysician",
      card: CARD,
      action: "SAVE",
      encounter: props.encounter_id,
      onSuccess: () => {
        props.onRefreshAndClose?.();
      },
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD}`, null);
          props.setProp(`successMessage.${CARD}`, null);
        }}
        error={props.errorMessage?.[CARD]}
        success={props.successMessage?.[CARD]}
        languageUX={props.languageUX}
      />

      <CardAddCoPhysicianUX
        // function
        onClose={props.onClose}
        onAddCoPhysician={handleAddCoPhysician}
        // data
        primary_doctor={props.primary_doctor || ""}
        // component
        addDoctorSearchbox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="co_physician"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            upward={true}
            inline
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.AddCoPhysicianSequence?.coPhysician?.id || null}
            setSelectedItem={handleSelectCoPhysician}
          />
        }
        coPhysicianComponent={
          <List divided verticalAlign="middle">
            {props.AddCoPhysicianSequence?.coPhysicianList?.length > 0 ? (
              <>
                {props.AddCoPhysicianSequence?.coPhysicianList?.map((item: any, index: number) => {
                  return (
                    <List.Item key={index}>
                      {item?.can_delete && (
                        <List.Content floated="right">
                          <Button
                            color="red"
                            icon="minus"
                            onClick={() => {
                              let items = [...props.AddCoPhysicianSequence.coPhysicianList];
                              items.splice(index, 1);
                              props.setProp("AddCoPhysicianSequence.coPhysicianList", items);
                            }}
                            size="mini"
                          ></Button>
                        </List.Content>
                      )}
                      <List.Content>{item?.name_code.replace(`(${item?.code})`, "") || ""}</List.Content>
                    </List.Item>
                  );
                })}
              </>
            ) : (
              <div style={{ textAlign: "center", color: "grey" }}>ไม่มีแพทย์ผู้ร่วมรักษา</div>
            )}
          </List>
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD}_SAVE`]}
            // config
            color="green"
            title="บันทึก"
          />
        }
      />
    </>
  );
};

export default CardAddCoPhysician;
