import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Label,
  Dropdown,
  Input,
  TextArea
} from 'semantic-ui-react'

const CardAdmitUX = (props: any) => {
    return(
      <div>
        <div
          className="ui segment raised yellow"
          id="CardAdmitUX-div-1"
          style={{boxShadow: "none"}}>
          
          <Label
            className="large title"
            color="yellow"
            id="CardAdmitUX-Label-37"
            ribbon={true}>
            จองห้อง
          </Label>
          <div
            className="ui form"
            id="CardAdmitUX-div-19">
            
            <div
              id="CardAdmitUX-div-20"
              style={{width: "100%"}}>
              {props.ErrorMessage}
            </div>
            <FormGroup
              id="CardAdmitUX-FormGroup-21"
              inline={true}>
              <FormField
                className="required"
                id="CardAdmitUX-FormField-27"
                inline={true}
                width={3}>
                <label
                  id="CardAdmitUX-label-33"
                  style={{textAlign: "right", width: "40px"}}>
                  HN
                </label>
                <div
                  id="CardAdmitUX-div-34"
                  style={{width: "100%"}}>
                  {props.PatientSearchBox}
                </div>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-29"
                inline={true}
                width={4}>
                <label
                  id="CardAdmitUX-label-35"
                  style={{textAlign: "right", width: "70px"}}>
                  ชื่อ-สกุล
                </label>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-36"
                  style={{width: "100%"}}>
                  {props.fullName || "-"}
                </Label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-31"
                inline={true}
                width={3}>
                <label
                  id="CardAdmitUX-label-38"
                  style={{textAlign: "right", width: "190px"}}>
                  วัน/เดือน/ปีเกิด
                </label>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-39">
                  {props.birthdate || "-"}
                </Label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-31"
                inline={true}
                width={3}>
                <label
                  id="CardAdmitUX-label-age"
                  style={{textAlign: "right", width: "40px"}}>
                  อายุ
                </label>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-agevalue">
                  {props.age || "-"}
                </Label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-31"
                inline={true}
                width={3}>
                <label
                  id="CardAdmitUX-label-race"
                  style={{textAlign: "right", width: "90px"}}>
                  เชื้อชาติ
                </label>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-racevalue">
                  {props.race || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-44"
                  style={{textAlign: "right", width: "100%"}}>
                  Diagnosis
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div>
                  {props.diagnosis}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-44"
                  style={{textAlign: "right", width: "100%"}}>
                  แพทย์เจ้าของไข้
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Input
                  icon="search"
                  readOnly={true}
                  value={props.primaryDoctorName}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardAdmitUX-FormGroup-22"
              inline={true}>
              <FormField
                className="required"
                id="CardAdmitUX-FormField-40"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-44"
                  style={{textAlign: "right", width: "100%"}}>
                  เลขที่ใบจอง
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-41"
                inline={true}
                width={3}>
                <Dropdown
                  fluid={true}
                  id="CardAdmitUX-Dropdown-45"
                  onChange={props.onChangeReserveCode}
                  options={props.reserveOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.reserveCode}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-42"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-46"
                  style={{textAlign: "right", width: "100%"}}>
                  สถานะใบจอง
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-43"
                inline={true}
                width={4}>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-47">
                  {props.reserveStatus || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardAdmitUX-FormGroup-23"
              inline={true}>
              <FormField
                className="required"
                id="CardAdmitUX-FormField-48"
                inline={true}
                width={2}>
                <label
                  className="required"
                  id="CardAdmitUX-label-61"
                  style={{textAlign: "right", width: "100%"}}>
                  ต้องการจองห้อง
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-49"
                inline={true}
                width={3}>
                <div
                  id="CardAdmitUX-div-62"
                  style={{width: "100%"}}>
                  {props.CardRoomSearch}
                </div>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-50"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-63"
                  style={{textAlign: "right", width: "100%"}}>
                  ประเภทหอผู้ป่วย
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-51"
                inline={true}
                width={4}>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-64">
                  {props.roomName || "-"}
                </Label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-52"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-65"
                  style={{textAlign: "right", width: "100%"}}>
                  สถานะห้อง
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-53"
                inline={true}
                width={3}>
                <Label
                  className="large fluid"
                  id="CardAdmitUX-Label-66">
                  {props.roomStatus || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-44"
                  style={{textAlign: "right", width: "100%"}}>
                  หมายเหตุ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <TextArea
                  rows={2}
                  value={props.remark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <div
              className="fields inline"
              id="CardAdmitUX-div-73"
              style={{display: "none"}}>
              
              <FormField
                id="CardAdmitUX-FormField-79"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-81"
                  style={{textAlign: "right", width: "100%"}}>
                  Consultation
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-80"
                inline={true}
                width={15}>
                <Input
                  id="CardAdmitUX-Input-82">
                </Input>
              </FormField>
            </div>
            <div
              className="fields inline"
              id="CardAdmitUX-div-72"
              style={{display: "none"}}>
              
              <FormField
                id="CardAdmitUX-FormField-74"
                inline={true}
                width={5}>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-75"
                inline={true}
                width={2}>
                <label
                  id="CardAdmitUX-label-77"
                  style={{textAlign: "right", width: "100%"}}>
                  รหัสผู้บันทึก
                </label>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-76"
                inline={true}
                width={6}>
                <Input
                  id="CardAdmitUX-Input-78">
                </Input>
              </FormField>
            </div>
            <FormGroup
              id="CardAdmitUX-FormGroup-26"
              inline={true}>
              <FormField
                id="CardAdmitUX-FormField-59"
                inline={true}
                width={13}>
              </FormField>
              <FormField
                id="CardAdmitUX-FormField-60"
                inline={true}
                width={3}>
                <div
                  id="CardAdmitUX-div-83"
                  style={{ width:"100%"}}>
                  {props.ButtonSave}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardAdmitUX

export const screenPropsDefault = {}

/* Date Time : Wed Feb 05 2025 12:20:23 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui segment raised yellow"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-19"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-20"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormGroup-21"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormGroup-22"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormGroup-23"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormGroup-26"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-27"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-29"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-31"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-33"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"40px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-34"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-35"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"70px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Label",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fullName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-36"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จองห้อง"
        },
        "className": {
          "type": "value",
          "value": "large title"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-37"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เดือน/ปีเกิด"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-38"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"190px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Label",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthdate || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-39"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-40"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-41"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-42"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-43"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบจอง"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-44"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Dropdown-45"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeReserveCode"
        },
        "options": {
          "type": "code",
          "value": "props.reserveOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.reserveCode"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะใบจอง"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-46"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Label",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reserveStatus || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-47"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-48"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-49"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-50"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-51"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-52"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-53"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 26,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-59"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 26,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-60"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ต้องการจองห้อง"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-61"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardRoomSearch"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-62"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-63"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Label",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-64"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-65"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Label",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomStatus || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-66"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "fields inline"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-72"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "fields inline"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-73"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 72,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-74"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 72,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-75"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 72,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-76"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผู้บันทึก"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-77"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 76,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Input-78"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-79"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 73,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-80"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Consultation"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-81"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Input-82"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-div-83"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-31"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-age"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"40px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Label",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-agevalue"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAdmitUX-FormField-31"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "เชื้อชาติ"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-race"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"90px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Label",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.race || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-Label-racevalue"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormGroup",
      "parent": 19,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "Diagnosis"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-44"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormGroup",
      "parent": 19,
      "props": {
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-44"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormGroup",
      "parent": 19,
      "props": {
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์เจ้าของไข้"
        },
        "id": {
          "type": "value",
          "value": "CardAdmitUX-label-44"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "code",
          "value": "props.diagnosis"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "TextArea",
      "parent": 103,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 104,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 101,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.primaryDoctorName"
        }
      },
      "seq": 105,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardAdmitUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
