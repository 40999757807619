import React, { useEffect, useState, useMemo } from "react";

import CardAdmitUX from "./CardAdmitUX";
import CardRoomSearch from "./CardRoomSearch";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SubICDDetail from "react-lib/apps/DPO/SubICDDetail";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";
import { useIntl } from "react-intl";

const CARD_RESERVE_BED = "CardReserveBed";

const CardAdmit = (props: any) => {
  const intl = useIntl();
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const [roomDetail, setRoomDetail] = useState<any>({});
  const [reserveCode, setReserveCode] = useState<string>("");
  const [admitOrder, setAdmitOrder] = useState<any[]>([]);
  const [reserveStatus, setReserveStatus] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    const getAdmitOrderList = async () => {
      const result = await props.getAdmitOrderList({
        encounter__patient__hn: selectedPatient?.hn,
        not_finished: true,
        can_reserve: true,
      });

      const items: any[] = result[0]?.items || [];
      const item =
        items.find((item) => item.code === props.reserveCode) || items[0];

      setAdmitOrder(items);

      handleChangeReserveCode(null, {
        value: item?.code,
        original: item,
      });
    };

    if (selectedPatient?.hn) {
      getAdmitOrderList();
    } else {
      handleClear();
    }
  }, [selectedPatient]);

  const reserveOptions = useMemo(() => {
    return admitOrder.map((item: any, index: number) => ({
      key: index + 1,
      value: item.code,
      text: item.code,
      original: item,
    }));
  }, [admitOrder]);

  const selectedAdmitOrder = useMemo(() => {
    const item = admitOrder?.find((item) => item.code === props.reserveCode);
    return item
  }, [admitOrder]);

  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    setSelectedPatient(id ? { id, hn, full_name, birthdate } : {});
  };

  const handleChangeReserveCode = (e: any, v: any) => {
    setReserveCode(v.value || "");
    setReserveStatus(v.original?.status_label || "");
  };

  const handleClear = () => {
    setAdmitOrder([]);
    setReserveCode("");
    setReserveStatus("");
  };

  const handleChangeRoom = (room: any) => {
    setRoomDetail(room || {});
  };

  const handleSaveAdmit = () => {
    props.onSave?.({
      room: roomDetail,
      patient: props.patientInfo,
      reserve: reserveOptions.find((item: any) => item.value === reserveCode)
        ?.original,
    });
    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenModConfirm = () => [setOpenConfirm(true)];

  return (
    <div>
      <CardAdmitUX
        // data
        fullName={selectedPatient?.full_name || ""}
        birthdate={selectedPatient?.birthdate || ""}
        reserveOptions={reserveOptions}
        onChangeReserveCode={handleChangeReserveCode}
        reserveCode={reserveCode}
        reserveStatus={reserveStatus}
        roomName={roomDetail?.room_name || ""}
        roomStatus={roomDetail?.room_status || ""}
        allowSave={selectedPatient?.hn && reserveCode && roomDetail?.room_no}
        age={selectedAdmitOrder?.age || ""}
        race={props.patientInfo.race_name || ""}
        primaryDoctorName={selectedAdmitOrder?.primary_doctor_name || ""}
        remark={selectedAdmitOrder?.remark || ""}
        // Element
        PatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // data
            defaultValue={props.patientInfo?.hn}
            // callback
            onEnterPatientSearch={handleChangePatient}
            languageUX={props.languageUX}
          />
        }
        CardRoomSearch={
          <CardRoomSearch
            // callback
            getRoomList={props.getRoomList}
            onEnter={handleChangeRoom}
            onSelect={handleChangeRoom}
            // options
            wardTypeOptions={props.masterOptions.wardType}
            roomTypeOptions={props.masterOptions.roomType}
            roomStatusOptions={props.masterOptions.roomStatus}
            defaultValue={props.roomNo}
            languageUX={props.languageUX}
          />
        }
        diagnosis={
          <SubICDDetail
              controller={props.subICDController}
              // config
              marginTop="0px"
              marginBottom="0px"
              inputSize="small"
              isReadOnly={true}
              isShowDeleteButton={false}
              hideActiveDisease={true}
              initialData={{
                icd10: selectedAdmitOrder?.icd10,
                type: 1,
                medical_description: selectedAdmitOrder?.diagnosis,
                icd_term: selectedAdmitOrder?.icd10_detail?.term,
                icd_code: selectedAdmitOrder?.icd10_detail?.code
              }}
              card="CardAdmit"
              languageUX={props.languageUX}
            />
        }
        // callback
        onSave={handleOpenModConfirm}
        ButtonSave={
          <ButtonLoadCheck
            // function
            id="btn-confirmData"
            setProp={props.setProp}
            onClick={handleOpenModConfirm}
            // data
            paramKey={`${CARD_RESERVE_BED}_room_reserve`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RESERVE_BED}_room_reserve`]}
            color="green"
            disabled={!(selectedPatient?.hn && reserveCode && roomDetail?.room_no)}
            title={intl.formatMessage({ id: "บันทึกรายการ" })}
            style={{
              width: "100%",
            }}
          />
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openConfirm}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        // @ts-ignore
        content={
          <>
            ต้องการบันทึกการจองห้อง/เตียง {roomDetail?.room_no} ให้ผู้ป่วย HN {selectedPatient?.hn}{" "}
            ชื่อ {selectedPatient?.full_name} ในวันที่{" "}
            {reserveOptions.find((item: any) => item.value === reserveCode)?.original?.admit_date}{" "}
            ใช่หรือไม่{" "}
          </>
        }
        onApprove={handleSaveAdmit}
        onDeny={handleCloseModConfirm}
      />
    </div>
  );
};

export default CardAdmit;
